<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import ScaleBox from 'vue2-scale-box';
export default {
  name: "App",
  components: {
    ScaleBox
  },
};
</script>

<style>
body{
  padding: 0;
  margin: 0;
}</style>
