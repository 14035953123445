<template>
    <div class="container">
        <!-- 走马灯 -->
        <div class="img">
            <el-carousel :interval="0" arrow="always" indicator-position="none" :initial-index="imgIndex">
                <el-carousel-item v-for="item in petsList" :key="item.index">
                    <img :src="item.url" alt="" height="80%" width="100%" class="image">
                </el-carousel-item>
            </el-carousel>
            <!-- 关闭按钮 -->
            <span class="button"><el-button circle icon="el-icon-close" @click="closePop"></el-button></span>
        </div>

    </div>
</template>
<script>
export default {
    // 接收父组件传过来的图片index以及关闭弹窗函数
    props: [
        'index','imgDetail'
    ],
    data() {
        return {
            imgIndex: '',
            petsList: [
            {url: require("@/assets/travel1.jpg"),index: 0},
                { url: require("@/assets/travel3.jpg"), index: 1 },
                { url: require("@/assets/movie1.jpg"), index: 2 },
                { url: require("@/assets/movie2.jpg"), index: 3 },
                { url: require("@/assets/game1.jpg"), index: 4 },
                { url: require("@/assets/game2.jpg"), index: 5 },
                { url: require("@/assets/food1.jpg"), index: 6 },
                { url: require("@/assets/food2.jpg"), index: 7 },
                { url: require("@/assets/dog2.jpeg"), index: 8 },
                { url: require("@/assets/cat.jpeg"), index: 9 }
            ]
        }
    },
    created() {
        // 使走马灯初始图片index为所传index
        this.imgIndex = this.index   
    },
    methods:{
        // 调用父组件函数关闭弹窗
        closePop(){
            this.imgDetail(0)
        }
    }
}
</script>
<style scoped>
/* 设置背景朦层 */
.container {
    width: 750px;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
}

.img {
    width: 340px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top:20px
}

.image {
    object-fit: cover;
}

/* 走马灯宽高 */
::v-deep .el-carousel__container {
    height: 180px;
    width: 300px;
}

/* 消除按钮边框 */
.el-button {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: white;
}</style>