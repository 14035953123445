<template>
    <div class="container">
        <!-- 头部导航 -->
        <div class="headBar">
            <headBar></headBar>
        </div>
        <!-- 文本内容 -->
        <div class="content">
            <carousel/>
        </div>
        <!-- 底部公司名 -->
        <div class="foot">
            <footBar/>
        </div>
    </div>
</template>
<script>
import headBar from "@/components/header/headBar.vue"
import carousel from "@/components/mid/contentCard.vue"
import footBar from "@/components/foot/footBar.vue"
export default {
    components: {
        headBar,
        carousel,
        footBar
    }
}
</script>
<style scoped>
/* 整体纵向排布 */
.container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
.headBar{
    height: 30px;
    position: fixed;
    z-index: 1500;
    width: 750px;
}
.content{
    width: 750px;
    margin: auto;
    height: 1400px;
    margin-bottom: 12px;
    margin-top: 30px;
}

</style>
