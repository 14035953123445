<template>
    <div class="container">
        <div class="headBar">
            <img src="@/assets/dico.png" alt="" class="img" @click="$router.push('/')">
            <span style="width: 10px;"></span>
            <span class="title" @click="$router.push('/')">Dico</span>
            <!-- 填充多余空间使图标和导航分离 -->
            <div class="occu"></div>
            <ul class="ulist">
                <li>
                    <a href="/">Home</a>
                </li>
                <!-- <li>
                    <a href="">Download</a>
                </li> -->
                <li>
                    <a @click="$router.push('/privacy')">Privacy Policy</a>
                </li>
                <li>
                    <a @click="$router.push('/user')">User Agreement</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.title{
    font-size: 20px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 30px;
}
.title:hover{
    cursor: pointer;
}
/* 填充div */
.occu {
    width: 370px;
}
/* 导航栏横向排布 */
.headBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 750px;
    background-color: rgba(0, 0, 0, 0.9);
    height: 30px;
}

.container {
    display: block;

    height: 30px;
    /* 固定导航栏不随滚轮移动 */

}

/* 首部导航横向布局均匀分布 */
.ulist {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    flex:1;
    line-height: 30px;
}

.img {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    border-radius: 50%;
    margin-left: 30px;
}

/* 点击logo跳转回首页 */
.img:hover {
    cursor: pointer;
}

li {
    /* 消除列表圆点 */
    list-style: none;
}

a {
    /* 消除链接下划线和颜色 */
    text-decoration: none;
    color: #faf6f6;
    font-size: 10px;

}
a:hover{
    cursor: pointer;
}
</style>