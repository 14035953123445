import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/components/dico.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  //隐私政策
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '@/components/privacy/privacyPolicy.vue')
  },
//   用户须知
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '@/components/user/userAgree.vue')
  },
//删除账户
{
  path: '/request',
  name: 'request',
  component: () => import(/* webpackChunkName: "about" */ '@/components/request/requestPage.vue')
},
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
