<template>
    <div class="container">
        <!-- 图片点击弹窗 -->
        <imgDetail class="pop" v-if="showImg" :index="index" :imgDetail="imgDetail"></imgDetail>
        <div class="pic_item">
            <!-- 头部图片走马灯 -->
            <el-carousel :interval="4000" arrow="always">
                <el-carousel-item v-for="item in backPic" :key="item.index">
                    <img :src="item.url" alt="" height="100%" width="100%">
                    <h3>{{ item.tip }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- App简介部分 -->
        <div class="about_us">
            <ul class="ul_list">
                <h1>About Us</h1>
                <li class="detail li_pa">An app where you can share your cute pets</li>
                <li class="detail li_pa">An app where you can share travel experiences</li>
                <li class="detail li_pa">An app where you can share local cuisine</li>
                <li class="detail li_pa">An app where you can share good movies</li>
                <li class="detail li_pa">An app where you can share excellent games</li>
                <p class="about_p">We are an app where you can share or find things that interest you and like-minded
                    friends.</p>
            </ul>
            <img src="@/assets/community.jpg" alt="" class="image">
        </div>
        <!-- App功能部分 -->
        <div class="services">
            <h1 class="service_header">SERVICES</h1>
            <ul class="service_ul">
                <li class="service_li">
                    <span class="iconfont icon-iconfontzhizuobiaozhunbduan36"></span>
                    <h2 class="h2_title">MicroBlog Square</h2>
                    <span class="detail">find what interests you</span>
                </li>
                <li class="service_li">
                    <span class="iconfont icon-friends-"></span>
                    <h2 class="h2_title">Make Friends</h2>
                    <span class="detail">make friends with similar interests</span>
                </li>
                <li class="service_li">
                    <span class="iconfont icon-baike"></span>
                    <h2 class="h2_title">Record Life</h2>
                    <span class="detail">share whate interests you</span>
                </li>
                <li class="service_li">
                    <span class="iconfont icon-jiaoliu"></span>
                    <h2 class="h2_title">Communication</h2>
                    <span class="detail">communitcate with like-minded people</span>
                </li>
            </ul>
        </div>
        <!-- 宠物展示部分 -->
        <div class="pets">
            <h1 class="pets_title">Discovering Interests</h1>
            <ul style="margin: 0;padding: 0;" class="pets_ul">
                <li v-for="item in petsList" :key="item.index"><img :src="item.url" alt="" class="pets_img"
                        @click="imgDetail(item.index)">
                </li>
            </ul>
        </div>
        <!-- 下载部分   -->
        <div class="download">
            <h1 class="download_title">WELCOME</h1>
            <p class="download_detail">We look forward to your joining us to share your life and interests!</p>
            <p class="download_detail">--Dico</p>
            <!-- <el-button icon="el-icon-download" type="primary" round>Download</el-button> -->
        </div>
        <div class="download_button">
            <img src="@/assets/download_apple.png" alt="" class="download_image" @click="download('apple')">
            <img src="@/assets/download_google.png" alt="" class="download_image" @click="download('google')">
        </div>
    </div>
</template>
<script>
import imgDetail from '@/components/mid/imgDetail.vue'
export default {
    components: {
        imgDetail
    },
    data() {
        return {
            // 控制图片展示窗口显示
            showImg: false,
            index: "",
            // 走马灯图片
            backPic: [
                {
                    url: require("@/assets/travel.jpg"),
                    tip: "Travel to understand the world",
                    index: 0
                },
                {
                    url: require("@/assets/movie.jpg"),
                    tip: "Share or discover good movies",
                    index: 1
                },
                {
                    url: require("@/assets/game.jpg"),
                    tip: "Make friends through games",
                    index: 2
                },
                {
                    url: require("@/assets/2.jpeg"),
                    tip: "Enjoy Life With Pets",
                    index: 3
                },
                {
                    url: require("@/assets/food.jpg"),
                    tip: "Understand local cuisine",
                    index: 4
                }
            ],
            // 宠物展示图片
            petsList: [
                { url: require("@/assets/travel1.jpg"), index: 0 },
                { url: require("@/assets/travel3.jpg"), index: 1 },
                { url: require("@/assets/movie1.jpg"), index: 2 },
                { url: require("@/assets/movie2.jpg"), index: 3 },
                { url: require("@/assets/game1.jpg"), index: 4 },
                { url: require("@/assets/game2.jpg"), index: 5 },
                { url: require("@/assets/food1.jpg"), index: 6 },
                { url: require("@/assets/food2.jpg"), index: 7 },
                { url: require("@/assets/dog2.jpeg"), index: 8 },
                { url: require("@/assets/cat.jpeg"), index: 9 }
            ]
        };
    },
    methods: {
        // 给图片弹窗传图片序号
        imgDetail(index) {
            this.showImg = !this.showImg
            this.index = index
        },
        download(platform) {
            if (platform == "apple") {
                window.open("https://apps.apple.com/app/wechat/id6467689175")
            }else{
                window.open("https://play.google.com/store/apps/details?id=org.dico.dream.android")
            }
        }
    }
}
</script>
<style scoped>
.container {
    height: 1900px;
}

/* 走马灯高度修改 */
::v-deep .el-carousel__container {
    height: 349px;
}

.pic_item {
    position: relative;
    width: 100%;
}

/* 走马灯内嵌文字样式 */
.pic_item h3 {
    position: absolute;
    width: 100%;
    bottom: 110px;
    font-size: 40px;
    color: white;
    font-weight: 900;
    line-height: 116%;
    align-items: center;
    text-align: center;
    flex: none;
    order: 1;
    align-self: stretch;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* aboutus板块样式 */
.about_us {
    margin-left: 125px;
    display: flex;
    margin-top: 40px;
    height: 210px;
    width: 500px;
}

.about_p {
    height: 50px;
}

h1 {
    font-size: 20px;
}

.h2_title {
    font-size: 10px;
}

.detail {
    font-size: 8px;
    color: rgb(138, 130, 130);

}

.ul_list {
    width: 200px;
    height: 200px;
    overflow: auto;
    padding-left: 0;
    margin: 0;
}

p {
    font-size: 8px;
}

/* 图片样式 */
.image {
    width: 250px;
    height: 200px;
    display: block;
    margin-left: 20px;
    object-fit: cover;
}

/* 下载图片样式 */
.download_image {
    width: 100px;
    height: 40px;
    object-fit: contain;
    margin-left: 40px;

}

/* 下载按钮 */
.download_button {
    /* display: flex; */
    position: fixed;
    z-index: 1500;
    margin-left: 220px;
    margin-top: 250px;
}

.download_button:hover {
    cursor: pointer;
}

/* service服务板块样式 */
.services {
    height: 210px;
    background-image: url("@/assets/social2.jpg");
    background-size: 100% 120%;
    overflow: auto;
    object-fit: cover;
    margin-top: 30px;

}

.service_header {
    color: white;
    align-items: center;
    margin-left: 320px;
}

.service_ul {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    width: 750px;

}

.service_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

/* 宠物种类板块 */
.pets {
    width: 750px;
}

.pets_title {
    align-items: center;
    text-align: center;
}

.pets_ul {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 750px;
    flex-wrap: wrap;
}

.pets_img {
    height: 160px;
    width: 150px;
    margin-top: -4px;
}

.pets_img:hover {
    scale: 1.2;
}

/* download板块 */

.download {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download_detail {
    font-size: 10px;
    color: rgb(138, 130, 130);
}

/* 列表圆点消除 */
ul {
    list-style: none;

}

/* 列表内边距设置 */
.li_pa {
    margin: 5px;
}

/* 图片保持比例铺满 */
img {
    object-fit: cover;
}

/* 图片弹窗样式 */
::v-deep .pop {
    /* 悬浮在页面最上层 */
    width: 750px;
    height: 600px;
    overflow: hidden;
    z-index: 1500;
    position: fixed;
}
</style>